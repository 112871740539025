<template>
  <v-container class="p-3">
    <loading-flux :value="loadingAll"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
        {{
          $route.query.sap_project ? `| COD: ${$route.query.sap_project}` : ""
        }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="start" class="mt-1">
      <v-col cols="12" md="4" sm="12">
        <v-btn
          @click="openModalCreateRequestMaterial"
          color="primary text-white"
          small
          v-if="!filterByUser"
        >
          <v-icon small class="mr-1"> mdi-plus </v-icon>
          Crear solicitud
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <label for="warehouse"> Filtrar por Bodega </label>
        <v-autocomplete
          v-model="filters.warehouse"
          clearable
          disabled
          :items="warehouses.items ? warehouses.items : []"
          @input="getRequestMaterialsData(1)"
          class=""
          item-value="code"
          item-text="name"
          label="Todos"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
        class="pb-0"
        v-if="!$route.query.sap_project"
      >
        <label for="proyect"> Filtrar por Proyecto </label>
        <v-autocomplete
          v-model="filters.project_code"
          clearable
          :items="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          @input="getRequestMaterialsData(1)"
          @update:search-input="getProjectsData"
          @click:clear="getProjectsData"
          class=""
          hide-details
          item-text="name_code"
          no-data-text="Sin proyectos"
          :loading="loadingProjects"
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0" v-else> </v-col>
      <v-col cols="12" md="4" sm="12">
        <div
          v-if="!$route.query.sap_project && (!stockItemGroup || filterByUser)"
        >
          <label for="proyect"> Filtrar por CECO </label>
          <v-autocomplete
            v-model="filters.cost_center_filter"
            clearable
            :items="cecos.items ? cecos.items : []"
            @input="getRequestMaterialsData(1)"
            @update:search-input="getCecosData"
            @click:clear="getCecosData"
            class=""
            hide-details
            item-text="name_code"
            no-data-text="Sin CECOS"
            :loading="loadingCecos"
            label="Todos"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="">
        <label for="search"> Filtro de Búsqueda </label>
        <v-text-field
          v-model="filters.filter"
          @input="getRequestMaterialsData(1)"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12" v-if="!filterByUser"></v-col>
      <v-col cols="12" md="4" sm="12" v-else class="">
        <div>
          <label for="proyect">
            Filtrar por Solicitud de Servicios/Materiales
          </label>
          <v-autocomplete
            v-model="filters.type_request"
            clearable
            :items="typeRequestsItems"
            @input="getRequestMaterialsData(1)"
            hide-details
            item-text="name"
            item-value="value"
            label="Todos"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="mt-2">
        <v-btn
          class="float-right mt-5"
          color="blue darken-2 text-white"
          small
          @click="downloadExcelRequestMaterials"
        >
          <v-icon small class="mr-1"> mdi-export </v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-3 px-3 mb-2 mt-2"
      :headers="wrappedHeaders"
      :items="wrappedRequestMaterials"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando solicitudes"
      item-key="id"
      hide-default-footer
      no-data-text="Sin materiales"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.type_destiny="{ item }">
        <span class="mt-2" style="font-size: 10px">
          {{ item.project ? "Proyecto" : "CECO" }}
        </span>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span class="mt-2" style="font-size: 12px">
          {{ formatDateToFormatCommon(new Date(item.created_at)) }}
        </span>
      </template>
      <template v-slot:item.received_at="{ item }">
        <span class="mt-2" style="font-size: 12px">
          {{
            item.received_at
              ? formatDateToFormatCommon(new Date(item.received_at))
              : formatDateToFormatCommon(new Date(item.created_at))
          }}
        </span>
      </template>
      <template v-slot:item.warehouse_code="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ CODE_WAREHOUSE_CENTRAL_FLUXSOLAR }}
        </span>
      </template>
      <template v-slot:item.warehouse_name="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ getWarehouseCentralName }}
        </span>
      </template>
      <template v-slot:item.stage="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ item.stage ? item.stage.name : "Sin etapa" }}
        </span>
      </template>
      <template v-slot:item.material_request_type="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{
            item.is_service
              ? item.material_request_type.name == REQUEST_MATERIAL
                ? "SS"
                : "SSU"
              : item.material_request_type.name == REQUEST_MATERIAL
              ? "SM"
              : "SMU"
          }}
        </span>
      </template>
      <template v-slot:item.user="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ item.user.email }}
        </span>
      </template>
      <template v-slot:item.warehouse_type="{ item }">
        <v-chip label small color="primary" class="mt-2 text-white">
          Central
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          label
          small
          :color="getColorStatus(item)"
          class="mt-2 text-white"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.code="{ item }">
        <span class="mt-2" style="font-size: 10px">
          {{ item.project ? item.project.code : item.cost_center.sap_code }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalReceptionRequestMaterials(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
                class="mx-2"
              >
                <v-icon> mdi-view-list-outline </v-icon>
              </v-btn>
            </template>
            <span>Ver productos de la solicitud</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalShowRequestMaterials(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon> mdi-truck-outline </v-icon>
              </v-btn>
            </template>
            <span>Ver las solicitudes</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.warning"
                class="mx-2"
                @click="retryRemainingRequestMaterials(item)"
                icon
                color="warning"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon> mdi-sync </v-icon>
              </v-btn>
            </template>
            <span>Solicitar materiales restantes</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="float-right my-3">
      <v-pagination
        v-if="requestMaterials.total_pages > 1"
        total-visible="8"
        v-model="filters.page"
        :length="requestMaterials ? requestMaterials.total_pages : 1"
      ></v-pagination>
    </div>
    <modal-create-request-material
      ref="modalCreateRequestMaterial"
      :modalAction="dialogCreateRequestMaterials"
      :closeModalCreateRequestMaterials="closeModalCreateRequestMaterials"
      :typeRequest="typeRequest"
      :stockItemGroup="stockItemGroup"
      @requestMaterialsCreated="getRequestMaterialsData"
      @approveRequestCreated="emitApproveRequestCreated"
    >
    </modal-create-request-material>
    <modal-show-request-materials
      :modalAction="dialogShowRequestMaterials"
      :closeModalShowRequestMaterials="closeModalShowRequestMaterials"
      :currentRequestMaterials="currentRequestMaterials"
    >
    </modal-show-request-materials>
    <modal-reception-request-materials
      :modalAction="dialogReceptionRequestMaterials"
      :closeModalReceptionRequestMaterials="closeModalReceptionRequestMaterials"
      :currentRequestMaterials="currentRequestMaterialsReception"
      @receptionUpdated="getRequestMaterialsData(1)"
    >
    </modal-reception-request-materials>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalCreateRequestMaterial from "./components/ModalCreateRequestMaterial.vue";
import ModalShowRequestMaterials from "./components/ModalShowRequestMaterials.vue";
import ModalReceptionRequestMaterials from "./components/ModalReceptionRequestMaterials.vue";
import {
  CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
  REQUEST_MATERIAL,
  REQUEST_MATERIAL_URGENT,
  REQUEST_SERVICE,
  REQUEST_SERVICE_URGENT,
  TYPE_REQUESTS_MATERIALS,
  TYPE_REQUEST_MATERIAL_ID,
  TYPE_REQUEST_SERVICE_ID,
  TYPE_REQUEST_MATERIAL_OR_SERVICE,
} from "@/constants/requestMaterial";
import { authUsuarioComputed } from "@/state/helpers";
import swal from "sweetalert2";
import { formatDateToFormatCommon } from "@/helpers/common";

export default {
  props: {
    //Prop para solicitudes de bodega móvil o solicitudes de bodegas standard
    typeRequest: {
      type: Number,
      default: TYPE_REQUESTS_MATERIALS.standard,
    },
    //Prop para titulo del componente
    title: {
      type: String,
      default: "Solicitudes de Material",
    },
    //Prop para filtrar las solicitudes por usuario
    filterByUser: {
      type: Boolean,
      default: false,
    },
    /*Prop para solicitudes con grupo de articulos Existencia (Solicitud de Materiales) o 
    Solicitudes con grupo de articulos de NO Existencia (Solicitud de Servicios)*/
    stockItemGroup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ModalCreateRequestMaterial,
    ModalReceptionRequestMaterials,
    ModalShowRequestMaterials,
  },
  mounted() {
    this.getRequestMaterialsData();
    this.getWarehousesData();
    if (!this.$route.query.sap_project) {
      this.getProjectsData();
      this.getCecosData();
    }
  },
  watch: {
    "filters.page": function (page) {
      this.getRequestMaterialsData(page);
    },
  },
  data() {
    return {
      REQUEST_MATERIAL,
      REQUEST_MATERIAL_URGENT,
      REQUEST_SERVICE,
      REQUEST_SERVICE_URGENT,
      CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
      TYPE_REQUESTS_MATERIALS,
      typeRequestsItems: TYPE_REQUEST_MATERIAL_OR_SERVICE,
      debounceTime: 500,
      debounceProjects: null,
      debounceCecos: null,
      debounceWarehouses: null,
      debounceRequests: null,
      filters: {
        page: 1,
        project_code: null,
        cost_center_filter: null,
        type_request: null,
        warehouse: CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
        filter: "",
      },
      pageCount: 0,
      loadingAll: false,
      loadingProjects: false,
      loadingCecos: false,
      currentRequestMaterials: null,
      currentRequestMaterialsReception: null,
      dialogCreateRequestMaterials: false,
      dialogShowRequestMaterials: false,
      dialogReceptionRequestMaterials: false,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: this.title,
          disabled: true,
          href:
            this.typeRequest == TYPE_REQUESTS_MATERIALS.mobile_warehouses
              ? "/supplying/mobile-warehouses/requests"
              : "/sap/request-materials",
        },
      ],
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Código Bodega",
          sortable: false,
          width: "5%",
          align: "center",
          value: "warehouse_code",
        },
        {
          text: "Nombre Bodega",
          align: "center",
          value: "warehouse_name",
          sortable: false,
          width: "15%",
        },
        {
          text: "Fecha de Creación",
          align: "center",
          value: "created_at",
          width: "5%",
          sortable: false,
        },
        {
          text: "Fecha de Entrega",
          align: "center",
          value: "received_at",
          width: "5%",
          sortable: false,
        },
        {
          text: "Tipo de Solicitud",
          align: "center",
          value: "material_request_type",
          width: "10%",
          sortable: false,
        },
        {
          text: "Usuario que Solicita",
          align: "center",
          value: "user",
          width: "3%",
          sortable: false,
        },
        {
          text: "Etapa Proyecto",
          align: "center",
          value: "stage",
          width: "8%",
          sortable: false,
        },
        {
          text: "Estado Solicitud",
          align: "center",
          value: "status",
          width: "10%",
          sortable: false,
        },
        {
          text: "Tipo Bodega",
          align: "center",
          value: "warehouse_type",
          width: "2%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "requestMaterialsSap/loading",
      requestMaterials: "requestMaterialsSap/requestMaterials",
      projects: "requestMaterialsSap/projects",
      cecos: "requestMaterialsSap/cecos",
      warehouses: "requestMaterialsSap/warehouses",
    }),
    wrappedRequestMaterials() {
      return this.requestMaterials.items;
    },
    getWarehouseCentralName() {
      return this.warehouses.items
        ? this.warehouses.items.find(
            (item) => item.code == CODE_WAREHOUSE_CENTRAL_FLUXSOLAR
          ).name
        : "-";
    },
    wrappedHeaders() {
      if (this.$route.query.sap_project) {
        return this.tableHeaders;
      }
      return [
        {
          text: "Proyecto",
          align: "center",
          value: "code",
          width: "8%",
          sortable: false,
        },
        ...this.tableHeaders,
      ];
    },
  },
  methods: {
    ...mapActions({
      getRequestMaterials: "requestMaterialsSap/getRequestMaterials",
      getProjects: "requestMaterialsSap/getProjects",
      getWarehouses: "requestMaterialsSap/getWarehouses",
      sendDownloadExcelRequestMaterials:
        "requestMaterialsSap/downloadExcelRequestMaterials",
      getCecos: "requestMaterialsSap/getCecos",
      sendRetryRemainingRequestMaterials:
        "requestMaterialsSap/retryRemainingRequestMaterials",
    }),
    formatDateToFormatCommon(date) {
      return formatDateToFormatCommon(date);
    },
    emitApproveRequestCreated(data) {
      this.$emit("approveRequestCreated", data);
    },
    openModalCreateRequestMaterial() {
      this.dialogCreateRequestMaterials = true;
      this.filters.project_code = null;
      this.$refs.modalCreateRequestMaterial.resetFormCreateRequestMaterial();
      if (!this.$route.query.sap_project) {
        this.getProjectsData({ type: "clear" });
      }
    },
    closeModalCreateRequestMaterials() {
      this.dialogCreateRequestMaterials = false;
    },
    openModalShowRequestMaterials(requestMaterial) {
      this.currentRequestMaterials = requestMaterial;
      this.dialogShowRequestMaterials = true;
    },
    closeModalShowRequestMaterials() {
      this.currentRequestMaterials = null;
      this.dialogShowRequestMaterials = false;
    },
    openModalReceptionRequestMaterials(requestMaterial) {
      this.currentRequestMaterialsReception = requestMaterial;
      this.dialogReceptionRequestMaterials = true;
    },
    closeModalReceptionRequestMaterials() {
      this.currentRequestMaterialsReception = null;
      this.dialogReceptionRequestMaterials = false;
    },
    async getRequestMaterialsData(page = 1) {
      let self = this;
      clearTimeout(self.debounceRequests);
      self.debounceRequests = setTimeout(async () => {
        await this.getRequestMaterials({
          ...this.filters,
          get_type: this.typeRequest,
          page,
          filter: this.filters.filter ? this.filters.filter : undefined,
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.filters.project_code
            ? this.filters.project_code.split(" - ")[0]
            : undefined,
          cost_center_filter: this.filters.cost_center_filter
            ? this.filters.cost_center_filter.split(" - ")[0]
            : undefined,
          warehouse:
            this.filters.warehouse == CODE_WAREHOUSE_CENTRAL_FLUXSOLAR
              ? undefined
              : this.filters.warehouse,
          user_filter: this.filterByUser ? this.user.email : undefined,
          is_service:
            this.filters.type_request == TYPE_REQUEST_SERVICE_ID
              ? true
              : this.stockItemGroup
              ? false
              : undefined,
          is_material:
            this.filters.type_request == TYPE_REQUEST_MATERIAL_ID ||
            this.stockItemGroup
              ? true
              : undefined,
        });
      }, self.debounceTime);
    },
    async getWarehousesData(search = null) {
      clearTimeout(this.debounceWarehouses);
      let self = this;
      this.debounceWarehouses = setTimeout(async function () {
        await self.getWarehouses({
          filter: search ? search : undefined,
          items: 30,
        });
      }, self.debounceTime);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function () {
          await self.getProjects({
            is_mobile:
              self.typeRequest == TYPE_REQUESTS_MATERIALS.mobile_warehouses
                ? true
                : undefined,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    async getCecosData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.cost_center_filter !== search)
      ) {
        this.loadingCecos = true;
        clearTimeout(this.debounceCecos);
        let self = this;
        this.debounceCecos = setTimeout(async function () {
          await self.getCecos({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingCecos = false;
        }, self.debounceTime);
      }
    },
    getColorStatus(item) {
      switch (item.status) {
        case "Abierta":
          return "primary";
        case "Recibida":
          return "yellow darken-2";
        case "Parcialmente recibida":
          return "yellow darken-2";
        case "Consumida":
          return "primary";
        case "Parcialmente consumida":
          return "primary";
        case "Cerrada":
          return "red darken-3";
        case "Parcialmente cerrada":
          return "red darken-3";
        default:
          break;
      }
    },
    async downloadExcelRequestMaterials() {
      this.loadingAll = true;
      const response = await this.sendDownloadExcelRequestMaterials({
        get_type: this.typeRequest,
        project_code: this.$route.query.sap_project
          ? this.$route.query.sap_project
          : undefined,
        user_filter: this.filterByUser ? this.user.email : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        this.$route.query.sap_project
          ? `${this.$route.query.sap_project}_solicitudes_materiales.xlsx`
          : `solicitudes_materiales.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loadingAll = false;
    },
    async retryRemainingRequestMaterials(item) {
      swal
        .fire({
          title: "Atención",
          text: "¿Estás seguro que quieres solicitar los materiales restantes?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, solicitar",
          cancelButtonText: "No, cancelar",
        })
        .then(async (result) => {
          if (result.value) {
            this.loadingAll = true;
            const response = await this.sendRetryRemainingRequestMaterials({
              id: item.id,
            });
            if (response.status == 200) {
              swal.fire({
                icon: "success",
                title: "Excelente",
                text: "Materiales restantes solicitados con éxito",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 6000,
                timerProgressBar: true,
              });
              this.getRequestMaterialsData(1);
            } else if (response.status == 400) {
              swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              swal.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
            this.loadingAll = false;
          }
        });
    },
  },
};
</script>

<style></style>
